import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Portrait from '../components/Portrait';

function IndexPage() {
  return (
    <Layout>
      <SEO keywords={[]} title='Home' />

      <section className='flex flex-col sm:mx-5 md:flex-row items-center'>
        <div className='mb-10 md:mb-0 md:mr-10'>
          <Portrait className='rounded-full shadow-2xl' />
        </div>
        <div className='p-5 bg-white sm:rounded-md shadow-2xl'>
          <p className='mb-2'>
            {
              'John West grew up in the rainy Willamette Valley before leaving his beloved Oregon apartment in the spring of 2019 to explore the country with his husband. Constantly on the move, John always finds something to distract him wherever he looks. From watching his husband make snow cones from seed-bearing cones and a handful of snow, to learning about the daily lives and cultures of the towns he visits. This nomadic lifestyle has led to fantastic experiences and culinary surprises.'
            }
          </p>
          <p>
            {
              'His husband tells him something interesting is always about to happen, and every time John is pulled away from his computer, he tries to keep his eyes wide open. However, whatever\'s out there is quickly enveloped by the brightness of that massive ball of burning gas thousands of miles away, forcing him to close his eyes; thus, he misses everything. However, imagining what he missed is a constant inspiration for him. It helps him continue to write about whatever romantic ideas claim him as he and his husband drive the traffic cluttered roads that make America.'
            }
          </p>
        </div>
      </section>
    </Layout>
  );
}

export default IndexPage;
