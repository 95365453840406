import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';
import Img from 'gatsby-image';

function Portrait(props) {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "portrait.jpg" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return <Img fixed={data.file.childImageSharp.fixed} {...props} />;
}

export default Portrait;
